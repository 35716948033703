/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1t9k8cm css-cw4rcc --style2 --full pb--60 pt--60" name={"hero"} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/63263338dc2c42cd9cca6cf27ff01615_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/63263338dc2c42cd9cca6cf27ff01615_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/63263338dc2c42cd9cca6cf27ff01615_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/63263338dc2c42cd9cca6cf27ff01615_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/63263338dc2c42cd9cca6cf27ff01615_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/63263338dc2c42cd9cca6cf27ff01615_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/63263338dc2c42cd9cca6cf27ff01615_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/63263338dc2c42cd9cca6cf27ff01615_s=3000x_.jpg);
    }
  
background-position: 54% 70%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pl--20 pr--20 pt--60" name={"o-nas"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":514}}>
              
              <Title className="title-box title-box--left" style={{"maxWidth":278}} content={"O nás"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":734}}>
              
              <Text className="text-box ff--1" style={{"maxWidth":565}} content={"<span style=\"color: rgb(0, 0, 0);\">Každá událost je unikátní, připravíme občerstvení, které podtrhne\n<br>váš výjimečný den.<br></span>"}>
              </Text>

              <Text className="text-box" content={"Většinu eventů pořádáme v unikátních prostorách historické budovy Národního muzea. Tuto lokalitu jsme si oblíbili natolik, až jsme otevřeli Bistro Národní muzeum a kavárnu Oliver's Coffee Cup jen pár kroků od této nádherné budovy."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--border2 pb--60 pt--60" name={"sluzby"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}} border={"2"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Image src={"https://cdn.swbpg.com/t/18947/19cc9ea0fae447bb842aeb44c852bbca_s=860x_.JPG"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":598}} srcSet={"https://cdn.swbpg.com/t/18947/19cc9ea0fae447bb842aeb44c852bbca_s=350x_.JPG 350w, https://cdn.swbpg.com/t/18947/19cc9ea0fae447bb842aeb44c852bbca_s=660x_.JPG 660w, https://cdn.swbpg.com/t/18947/19cc9ea0fae447bb842aeb44c852bbca_s=860x_.JPG 860w, https://cdn.swbpg.com/t/18947/19cc9ea0fae447bb842aeb44c852bbca_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/18947/19cc9ea0fae447bb842aeb44c852bbca_s=2000x_.JPG 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Image src={"https://cdn.swbpg.com/t/18947/9ad5f4d84c4c4e4e9922b7b16cc7bb53_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":714}} srcSet={"https://cdn.swbpg.com/t/18947/9ad5f4d84c4c4e4e9922b7b16cc7bb53_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/9ad5f4d84c4c4e4e9922b7b16cc7bb53_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/9ad5f4d84c4c4e4e9922b7b16cc7bb53_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/9ad5f4d84c4c4e4e9922b7b16cc7bb53_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/9ad5f4d84c4c4e4e9922b7b16cc7bb53_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Text className="text-box ff--1 fs--24 w--400" content={"Kompletní občerstvení pro vaše akce"}>
              </Text>

              <Text className="text-box" content={"Dle typu akce připravíme pohoštění na které vaši hosté nezapomenou. Kanapky, studený či teplý buffet, stačí říct na co se máme připravit a váš event se stane událostí!"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Text className="text-box ff--1 fs--24 w--400" content={"Od chlebíčku po kaviár "}>
              </Text>

              <Text className="text-box" content={"Výběr menu vždy řešíme individuálně přímo vám na míru. Umíme českou klasiku, mořské plody, dezerty vlastní výroby, ale i sushi."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Image src={"https://cdn.swbpg.com/t/18947/fc960b7b4b65480783dc445abde0232e_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":622}} srcSet={"https://cdn.swbpg.com/t/18947/fc960b7b4b65480783dc445abde0232e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/fc960b7b4b65480783dc445abde0232e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/fc960b7b4b65480783dc445abde0232e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/fc960b7b4b65480783dc445abde0232e_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/fc960b7b4b65480783dc445abde0232e_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Image src={"https://cdn.swbpg.com/t/18947/c03c08c87340492b98ad371f72e6a583_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":536}} srcSet={"https://cdn.swbpg.com/t/18947/c03c08c87340492b98ad371f72e6a583_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/c03c08c87340492b98ad371f72e6a583_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/c03c08c87340492b98ad371f72e6a583_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/c03c08c87340492b98ad371f72e6a583_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/c03c08c87340492b98ad371f72e6a583_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Text className="text-box ff--1 fs--24 w--400" content={"Catering do kanceláře i na oslavu "}>
              </Text>

              <Text className="text-box" content={"Uvaříme, dovezeme a připravíme vše pro vaše hosty. Ať už chystáte firemní večírek, společný oběd s kolegy nebo narozeninovou oslavu, občerstvení nechte na nás. "}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box title-box--left fs--24" content={"Důležitý event, galavečeře nebo coffee break?<br>"}>
              </Title>

              <Text className="text-box text-box--center fs--16" content={"Postaráme se o vás, a to doslova. Jsme tým profesionálů v oblasti cateringu i event managementu.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--60 pt--60" name={"galerie"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--left" content={"Galerie"}>
              </Title>

              <Image src={"https://cdn.swbpg.com/t/18947/97140c1074694e0cb8622d9e0bd883a8_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/97140c1074694e0cb8622d9e0bd883a8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/97140c1074694e0cb8622d9e0bd883a8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/97140c1074694e0cb8622d9e0bd883a8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/97140c1074694e0cb8622d9e0bd883a8_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/97140c1074694e0cb8622d9e0bd883a8_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="mt--80" src={"https://cdn.swbpg.com/t/18947/79402cf768434d59afa8de58a868391f_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/79402cf768434d59afa8de58a868391f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/79402cf768434d59afa8de58a868391f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/79402cf768434d59afa8de58a868391f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/79402cf768434d59afa8de58a868391f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/79402cf768434d59afa8de58a868391f_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="mt--80" src={"https://cdn.swbpg.com/t/18947/ed397baa68b747ddab1645e81725ae46_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/ed397baa68b747ddab1645e81725ae46_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/ed397baa68b747ddab1645e81725ae46_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/ed397baa68b747ddab1645e81725ae46_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/ed397baa68b747ddab1645e81725ae46_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/ed397baa68b747ddab1645e81725ae46_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/ee2be8f76c3f4e0798ae90281161a8d3_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":499}} srcSet={"https://cdn.swbpg.com/t/18947/ee2be8f76c3f4e0798ae90281161a8d3_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/ee2be8f76c3f4e0798ae90281161a8d3_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/ee2be8f76c3f4e0798ae90281161a8d3_s=860x_.png 860w, https://cdn.swbpg.com/t/18947/ee2be8f76c3f4e0798ae90281161a8d3_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/154a5a52b2654bd69d4ff8c39ae2c6d1_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/154a5a52b2654bd69d4ff8c39ae2c6d1_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/154a5a52b2654bd69d4ff8c39ae2c6d1_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/154a5a52b2654bd69d4ff8c39ae2c6d1_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/5bf36270d69d4c1cb83e8bd9ebac8619_s=860x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/5bf36270d69d4c1cb83e8bd9ebac8619_s=350x_.JPG 350w, https://cdn.swbpg.com/t/18947/5bf36270d69d4c1cb83e8bd9ebac8619_s=660x_.JPG 660w, https://cdn.swbpg.com/t/18947/5bf36270d69d4c1cb83e8bd9ebac8619_s=860x_.JPG 860w, https://cdn.swbpg.com/t/18947/5bf36270d69d4c1cb83e8bd9ebac8619_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/18947/5bf36270d69d4c1cb83e8bd9ebac8619_s=2000x_.JPG 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"gpty860jy7"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/0c95630bf21b48c5b5f8977212c84f7a_s=660x_.png"} url={"https://bistronarodnimuzeum.cz/"} use={"url"} href={"https://bistronarodnimuzeum.cz/"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/18947/0c95630bf21b48c5b5f8977212c84f7a_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/0c95630bf21b48c5b5f8977212c84f7a_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/0c95630bf21b48c5b5f8977212c84f7a_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="pt--20" src={"https://cdn.swbpg.com/t/18947/92291b2f2f3f4415a5c7aba9c7625ab9_s=660x_.png"} url={"https://oliverscoffeecup.cz/"} use={"url"} href={"https://oliverscoffeecup.cz/"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":209}} srcSet={"https://cdn.swbpg.com/t/18947/92291b2f2f3f4415a5c7aba9c7625ab9_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/92291b2f2f3f4415a5c7aba9c7625ab9_s=660x_.png 660w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/1fa2836e19d74ad6b499041d89e1e57f_s=660x_.png"} url={"https://olivacatering.cz/"} use={"url"} href={"https://olivacatering.cz/"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/18947/1fa2836e19d74ad6b499041d89e1e57f_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/1fa2836e19d74ad6b499041d89e1e57f_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/1fa2836e19d74ad6b499041d89e1e57f_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"reference"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--top" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Reference"}>
              </Title>

              <Image className="--left mt--0" src={"https://cdn.swbpg.com/t/18947/935acade07804f46b5a2bb2c80838f6b_s=860x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":1400}} srcSet={"https://cdn.swbpg.com/t/18947/935acade07804f46b5a2bb2c80838f6b_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/935acade07804f46b5a2bb2c80838f6b_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/935acade07804f46b5a2bb2c80838f6b_s=860x_.png 860w, https://cdn.swbpg.com/t/18947/935acade07804f46b5a2bb2c80838f6b_s=1400x_.png 1400w, https://cdn.swbpg.com/t/18947/935acade07804f46b5a2bb2c80838f6b_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--20" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--4 flex--center" columns={"4"} fullscreen={false}>
            
            <ColumnWrapper className="--right" style={{"maxWidth":215}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":123}} srcSet={"https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center" style={{"maxWidth":269}} content={"<span style=\"color: rgb(255, 255, 255);\"><span style=\"font-weight: bold;\">E-mail<br></span><br>info@olivacatering.cz</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(255, 255, 255);\"><span style=\"font-weight: bold;\">Telefon<br></span><br>+420 723 101 012</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center" content={"<span style=\"font-weight: 700; color: rgb(255, 255, 255);\">Sledujte nás</span><br>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<a href=\"https://www.instagram.com/olivacatering_cz/\" style=\"color: rgb(255, 255, 255);\">Instagram</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}